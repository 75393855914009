<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="公会id" prop="guildId" >
        <a-input v-model="form.guildId" placeholder="请输入公会id" />
      </a-form-model-item>
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="用户身份" prop="userIdentity" >
        <a-select placeholder="请选择用户身份" v-model="form.userIdentity">
          <a-select-option v-for="(d, index) in userIdentityOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="职位" prop="position" >
        <a-select placeholder="请选择职位" v-model="form.position">
          <a-select-option v-for="(d, index) in positionOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="签约开始日期" prop="signDateStart" >
        <a-date-picker style="width: 100%" v-model="form.signDateStart" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="签约结束日期" prop="signDateEnd" >
        <a-date-picker style="width: 100%" v-model="form.signDateEnd" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="首次签约时间" prop="firstSignDate" >
        <a-date-picker style="width: 100%" v-model="form.firstSignDate" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="续签次数" prop="renewTimes" >
        <a-input v-model="form.renewTimes" placeholder="请输入续签次数" />
      </a-form-model-item>
      <a-form-model-item label="上一次签约开始时间" prop="lastTimeSt" >
        <a-date-picker style="width: 100%" v-model="form.lastTimeSt" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="上一次签约到期时间" prop="lastTimeEnd" >
        <a-date-picker style="width: 100%" v-model="form.lastTimeEnd" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" v-if="formType === 1">
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGuild, addGuild, updateGuild } from '@/api/biz/guildUser'

export default {
  name: 'CreateForm',
  props: {
    userIdentityOptions: {
      type: Array,
      required: true
    },
    positionOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        guildId: null,

        userId: null,

        userIdentity: null,

        position: null,

        signDateStart: null,

        signDateEnd: null,

        firstSignDate: null,

        renewTimes: null,

        lastTimeSt: null,

        lastTimeEnd: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        guildId: null,
        userId: null,
        userIdentity: null,
        position: null,
        signDateStart: null,
        signDateEnd: null,
        firstSignDate: null,
        renewTimes: null,
        lastTimeSt: null,
        lastTimeEnd: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGuild({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGuild(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGuild(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
