<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID"   >
        <a-input v-model="form.userNo" placeholder="请输入用户ID"  :disabled="this.formType==2" style="color: #4b4b4d" />
      </a-form-model-item>
      <a-form-model-item label="邀请码"   >
        <a-input v-model="form.invitationCode"  placeholder="最多输入10个字符"  @input="validateInput" />

      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading"  @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {invitationCode  } from '@/api/biz/guildUser'
import { addGuildStaff, updateGuildStaff } from '@/api/biz/guildStaff'

export default {
  name: 'UpdateInviteCodeForm',
  props: {

  },
  components: {
  },
  data () {
    return {
      groupList:[],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        guildId: null,

        userId: null,

        userIdentity: null,

        position: null,

        signDateStart: null,

        signDateEnd: null,

        firstSignDate: null,

        renewTimes: null,

        lastTimeSt: null,

        lastTimeEnd: null,

        createTime: null,
        groupId:null,
        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        invitationCode: [
          { required: true, message: '邀请码不能为空', trigger: 'blur'  }

        ]


      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    validateInput(event) {
      const maxLength = 10;
      if (event.target.value.length > maxLength) {
        this.form.invitationCode = event.target.value.slice(0, maxLength);
      }
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        guildId: null,
        userId: null,
        userIdentity: null,
        position: null,
        signDateStart: null,
        signDateEnd: null,
        firstSignDate: null,
        renewTimes: null,
        lastTimeSt: null,
        lastTimeEnd: null,
        createTime: null, groupId:null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.formType = 1
      this.open = true
      let tttt=this.form;

      console.log(tttt);
      debugger
      this.form={}
      this.formTitle = '添加推广员'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      let that=this;
      that.reset()
      this.form={}
      that.formType = 2
      that.form = JSON.parse(JSON.stringify(row));
      that.formTitle = '修改邀请码'
      that.open = true

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.formType == 2) {
            if (this.form.id !== undefined && this.form.id !== null) {
              invitationCode(this.form).then(response => {
                this.$message.success(
                  '操作成功',
                  3
                )
                this.open = false
                this.$emit('ok')
              }).finally(() => {
                this.submitLoading = false
              })
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
