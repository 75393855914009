import request from '@/utils/request'


// 查询公会用户列表
export function listGuild(query) {
  return request({
    url: '/biz/guildUser/list',
    method: 'get',
    params: query
  })
}

// 查询公会用户分页
export function pageGuild(query) {
  return request({
    url: '/biz/guildUser/page',
    method: 'get',
    params: query
  })
}

// 查询公会用户详细
export function getGuild(data) {
  return request({
    url: '/biz/guildUser/detail',
    method: 'get',
    params: data
  })
}

// 新增公会用户
export function addGuild(data) {
  return request({
    url: '/biz/guildUser/add',
    method: 'post',
    data: data
  })
}

// 修改公会用户
export function updateGuild(data) {
  return request({
    url: '/biz/guildUser/edit',
    method: 'post',
    data: data
  })
}

// 删除公会用户
export function delGuild(data) {
  return request({
    url: '/biz/guildUser/delete',
    method: 'post',
    data: data
  })
}
//设置推广员
export function setPromoter(data) {
  return request({
    url: '/biz/guildUser/setPromoter',
    method: 'post',
    data: data
  })
}

// 公会邀请用户统计接口
export function getInviteUserStatistics(data) {
  return request({
    url: '/biz/guildUser/getInviteUserStatistics',
    method: 'get',
    params: data
  })
}
// 公会邀请用户统计接口
export function getUserInviteQuery(data) {
  return request({
    url: '/biz/inviteRecord/getUserInviteQuery',
    method: 'get',
    params: data
  })
}

// 获取分组列表
export function getGroupList(data) {
  return request({
    url: '/biz/userGrouping/list',
    method: 'get',
    data: data
  })
}
// 解约主播
export function cancelContract(data) {
  return request({
    url: '/biz/guildUser/cancelContract',
    method: 'post',
    data: data
  })
}

export function setGroup(data) {
  return request({
    url: '/biz/guildUser/setGroup',
    method: 'post',
    data: data
  })
}
// 离职用户
export function leaveOffice(data) {
  return request({
    url: '/biz/guildStaff/leaveOffice',
    method: 'post',
    data: data
  })
}
// 设置跳转房间号
export function setJumpRoomNo(data) {
  return request({
    url: '/biz/guildUser/setJumpRoomNo',
    method: 'post',
    data: data
  })
}

export function invitationCode(data) {
  return request({
    url: '/biz/guildUser/invitationCode',
    method: 'post',
    data: data
  })
}