import request from '@/utils/request'


// 查询公会员工列表
export function listGuild(query) {
  return request({
    url: '/biz/guildStaff/list',
    method: 'get',
    params: query
  })
}

// 查询公会员工分页
export function pageGuild(query) {
  return request({
    url: '/biz/guildStaff/page',
    method: 'get',
    params: query
  })
}
export function getGroupDataStatistics(query) {
  return request({
    url: '/biz/guildStaff/getGroupDataStatistics',
    method: 'get',
    params: query
  })
}
export function getRoomDataStatistics(query) {
  return request({
    url: '/biz/guildStaff/getRoomDataStatistics',
    method: 'get',
    params: query
  })
}

// 查询公会员工详细
export function getGuild(data) {
  return request({
    url: '/biz/guildStaff/detail',
    method: 'get',
    params: data
  })
}

// 新增公会员工
export function addGuildStaff(data) {
  return request({
    url: '/biz/guildStaff/add',
    method: 'post',
    data: data
  })
}

// 修改公会员工
export function updateGuildStaff(data) {
  return request({
    url: '/biz/guildStaff/edit',
    method: 'post',
    data: data
  })
}

// 删除公会员工
export function delGuild(data) {
  return request({
    url: '/biz/guildStaff/delete',
    method: 'post',
    data: data
  })
}
